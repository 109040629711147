import config from '../config.json';

const getConfigValue = name => (window._env && window._env[name]) || config[name];

export const _config = Object.keys(config).reduce((obj, key) => {
  obj[key] = getConfigValue(key);
  return obj;
}, {});

export const P21ApiUrl = getConfigValue('P21_API_URL');
