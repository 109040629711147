import { P21ApiUrl } from "../lib/config";

class P21ApiService {
  constructor(p21ApiUrl) {
    this.p21ApiUrl = p21ApiUrl;
  }

  async getGameStats() {
    const response = await fetch(`${this.p21ApiUrl}/api/game-stats`);
    if (!response.ok)
      return alert("An error occurred");

    return await response.json();
  }
}

export const p21ApiService = new P21ApiService(P21ApiUrl);
