import { useEffect, useState } from "react";
import { p21ApiService } from "../services/P21ApiService";

export default function DisplayGameStats() {
  const [gameStats, setGameStats] = useState(null);

  useEffect(() => {
    (async () => {
      const gameStats = await p21ApiService.getGameStats();
      setGameStats(gameStats);
    })();
  }, []);

  return <div>
    <h2>Game Stats</h2>
    {
      !gameStats
        ? <span>Loading</span>
        : <table>
          <tbody>
            <tr>
              <th>ID</th>
              <th>Players</th>
              <th>Created</th>
            </tr>
            {gameStats.map(game => <tr key={game.id}>
              <td>{game.id}</td>
              <td>{game.players?.length}</td>
              <td>{game.created}</td>
            </tr>)}
          </tbody>
        </table>
    }
  </div>;
}