import './App.css';
import DisplayGameStats from './components/DisplayGameStats';

function App() {
  return (
    <div className="App">
      <DisplayGameStats />
    </div>
  );
}

export default App;
